<template>
  <div>
    <div class="title">{{ $t("information") }}</div>
    <el-form ref="form" :model="form" label-width="210px">
      <el-form-item :label="$t('Orderamount')" prop="orderAmount">
        <span>{{ ((Number(form.orderAmount || 0) / $enums.UNIT_MILLION).toFixed(2) || 0) | toThousandFilterTwo }} {{$t('millionDollars')}}</span>
      </el-form-item>
      <el-form-item :label="$t('financingAmountRatio')" prop="rate" label-width="250px">
        <span>{{ form.financingDiscount || 0 }}%</span>
      </el-form-item>
      <el-form-item :label="$t('financingAmount')">
        <span>{{ ((Number(form.financingAmount || 0) / $enums.UNIT_MILLION).toFixed(2) || 0) | toThousandFilterTwo }} {{ $t("millionDollars") }}</span>
      </el-form-item>
      <el-form-item :label="$t('repaymentMethod')" prop="repaymentMethod" label-width="250px">
        <span>{{ $t("equalPrincipal") }}</span>
      </el-form-item>
      <el-form-item :label="$t('dateOfIssuance')" prop="shipmentDate">
        <span>{{ form.shipmentDate }}</span>
      </el-form-item>
      <el-form-item :label="$t('deadlinefForFund')" label-width="250px">
        <span>{{ form.deadlineFundRaising }}</span>
      </el-form-item>
      <el-form-item :label="$t('promisedPaymentDateAgain')" prop="promisedPaymentDate">
        <span>{{ form.promisedPaymentDate }}</span>
      </el-form-item>
      <el-form-item :label="$t('borrowingCycle')" label-width="250px">
        <span>{{ form.borrowingCycle }}&nbsp;{{ $t("day") }}</span>
      </el-form-item>
      <el-form-item :label="$t('financingAgent')" prop="financingAgent">
        <span>{{ form.financingAgent }}</span>
      </el-form-item>
      <el-form-item :label="$t('phoneNumber')" prop="phoneNumber" label-width="250px">
        <span>{{ form.phoneNumber }}</span>
      </el-form-item>
      <el-form-item v-if="form.boUser" :label="$t('brandOwner')" prop="username">
        <span>{{ form.boUser.username }}</span>
      </el-form-item>
      <el-form-item :label="$t('formTitleI18n.ContractReferenceNumber')" style="width: 100%;">
        <span>{{ form.contractNumber }}</span>
      </el-form-item>
      <!--      <el-form-item v-if="form.orderFile" :label="$t('uploadOrderFile')" style="width: 100%;">-->
      <!--        <template v-if="form.orderFile">-->
      <!--          <template v-for="(item, index) in JSON.parse(form.orderFile)">-->
      <!--            <el-link :underline="false" type="primary" @click="toReviewImg(item)" :key="index">{{-->
      <!--                item &&-->
      <!--                item.split("/")[item.split("/").length - 1]-->
      <!--              }}</el-link>-->
      <!--          </template>-->
      <!--        </template>-->
      <!--      </el-form-item>-->
      <el-form-item :label="$t('financingPurpose')" style="width: 1100px" prop="fiancingPurpose">
        <span>{{ form.fiancingPurpose }}</span>
      </el-form-item>
      <el-form-item :label="$t('receivableContract')" style="width: 100%;">
        <template v-if="form.orderFile">
          <template v-for="(item, index) in JSON.parse(form.orderFile)">
            <div :key="index">
              <el-link :underline="false" type="primary" @click="toReviewImg(item)">
                {{
                item &&
                item.split("/")[item.split("/").length - 1]
                }}
              </el-link>
            </div>
          </template>
        </template>
      </el-form-item>
    </el-form>
    <div class="title">
      {{ $t("confirmInformation") }}
      <!-- <span style="color: #81D8D0">！</span> -->
    </div>
    <el-form ref="formBank" :model="form" style="width: 100%;" label-width="210px">
      <el-form-item :label="$t('bankNumber')" prop="account">
        <span>{{ form.bankAccountNumber }}</span>
      </el-form-item>
      <el-form-item :label="$t('bankAccount')" prop="user" label-width="250px">
        <span>{{ form.accountHolderName }}</span>
      </el-form-item>
      <el-form-item :label="$t('bankOfDeposit')" prop="bank">
        <span>{{ form.receivingBank }}</span>
      </el-form-item>
      <el-form-item :label="$t('swiftCode')" label-width="250px">
        <span>{{ form.swiftCode }}</span>
      </el-form-item>
    </el-form>
    <div style="text-align: center">
      <el-button class="submit btn-black" v-on:click="cancel">
        {{
        $t("btnI18n.Close")
        }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { Decrypt } from "@/utils/crypto";
export default {
  data() {
    return {
      form: {},
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      let _this = this;
      this.$axios.get("/manage-repayment/order-detail", { params: { id: this.$route.query.id } }).then(res => {
        if (!res.code) {
          _this.form = res.data;
        }
      });
    },
    toReviewImg(url) {
      if (/.*(\.png|\.jpg|\.jpeg|\.gif|\.PNG|\.JPG|\.JPEG|\.GIF)$/.test(url)) {
        const image = new Image();
        image.src = url;
        const imgWindow = window.open(url);
        imgWindow.document.write(image.outerHTML);
      } else if (/.*(\.pdf|\.PDF)$/.test(url)) {
          let routeData = this.$router.resolve({
              query: {url:url},
              path:'/reviewPDF'
          });
          window.open(routeData.href, '_blank');
      } else {
          window.open(url);
      }
    },
    cancel() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.is-checked,
.is-indeterminate {
  .el-checkbox__inner {
    background-color: #81d8d0 !important;
    border-color: #81d8d0 !important;
  }
}
.is-focus,
.el-checkbox__inner:hover {
  // background-color: #81D8D0 !important;
  border-color: #81d8d0 !important;
  .el-checkbox__inner {
    border-color: #81d8d0 !important;
  }
}
.tip-view {
  margin-top: 20px;
  margin-left: 20px;
  span {
    color: #ff0101;
  }
}
.el-switch__label.is-active {
  color: #81d8d0;
}
.text-red {
  font-size: 14px;
  color: #ff0101;
}
.online-view {
  // margin-top: 10px;
  margin-left: 20px;
}
.el-form-item {
  width: 550px;
  display: inline-block;
}
.el-form-item__content {
  margin-left: 0px !important;
}
.end-date {
  .el-form-item {
    width: 160px;
  }
  .el-input,
  .el-input__inner {
    width: 160px;
  }
  .el-form-item__error {
    margin-left: 10px;
  }
}
.el-input,
.el-input__inner {
  width: 330px;
}
.el-textarea,
.el-textarea__inner {
  width: 890px;
  min-height: 80px !important;
}
.box-agree {
  .el-checkbox__inner {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .el-checkbox__inner::after {
    height: 10px;
    left: 7px;
    top: 2px;
  }
}
.submit {
  margin-top: 10px;
  padding: 6px 20px;
}
.el-form-item__error {
  margin-left: 140px;
}
</style>
